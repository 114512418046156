
import maintenanceService from '@/services/maintenanceService';

let state = { 
    toDoMaintenancesTree: [],
    lasatUpdateTreeDate: null,
    fetchingCount: 0
};
let mutations= {
    toDoMaintenancesTree(state, toDoMaintenancesTree) {
        state.toDoMaintenancesTree = toDoMaintenancesTree;
    },
    toDoMaintenancesTreeUpdated(state) {
        state.lasatUpdateTreeDate = new Date();
    },
    startFetching(state) {
        state.fetchingCount = state.fetchingCount + 1;
    },
    endFetching(state) {
        state.fetchingCount = state.fetchingCount - 1;
    }
};
let actions = {
    async loadToDoMaintenancesTree(context) {
        try { 
            if(context.state.lasatUpdateTreeDate && (new Date() - context.state.lasatUpdateTreeDate) / 1000 < 10)
                return; 
            context.commit("startFetching");
            
            let data = [];
            data = await maintenanceService.getToDoMaintenances();
            context.commit('toDoMaintenancesTree', data);
            context.commit('toDoMaintenancesTreeUpdated');
        } catch (e){
            context.commit("appStoreModule/addAppMessage", e, { root: true });
        }
        context.commit("endFetching");
    }
};

export default {
    namespaced : true,
    state,
    mutations,
    actions
}