import axios from 'axios';
export default {
    AUTH : {
      async doLogout() {
        let response = await axios.get('/G4/G4Account/G4Logout');
        return response;
      },
      async getAuthUserJWT() {
        let response = null;
        try {
          response = await axios.get('/SystemSettings/GetAuthUserJWT?flagAugeServices=1');
        } catch /*(err)*/ {
          // console.log(err);
        }
        
        if(!response || response.headers["content-type"].includes("text/html"))
        {
          let _origin = (location.origin.toLocaleLowerCase().includes("augeg4:3033") ? new URL("/", location.origin).href.replace(":3033","") : "/");
          window.location.href = _origin + "G4/";
          return;
        }
        
        return response;
      },
      parseJwt(token) {
        if(!token)
          return;
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
      
        return JSON.parse(jsonPayload);
      }
    },
    async getHistoricalMaintenances() {
      let response = await axios.get('/ReportBroadcastMaintenance/SelectMaintenanceDone'); 
      let rengionsTree = (response.data.Tree ? response.data.Tree.Regions : []);
      let arr = this.formatHistoricalMaintenances(rengionsTree); 
      return arr;
    },
    async getToDoMaintenances() {
      let response = await axios.post('/ReportBroadcastMaintenance/SelectMaintenanceToDo', {}); 
      let rengionsTree = (response.data.Tree ? response.data.Tree.Regions : []);
      let arr = this.formatToDoMaintenances(rengionsTree); 
      return arr;
    },
    async selectCAMReportAndMailData() {
      let response = await axios.post('/ReportBroadcastMaintenance/SelectCAMReportAndMailData');
      return response.data;
    },
    async selectRetiLocaliReportValidatedData() {
      let response = await axios.post('/ReportBroadcastMaintenance/SelectRetiLocaliReportValidatedData');
      return response.data;
    },
    async selectRetiLocaliReportToBeValidatedData() {
      let response = await axios.post('/ReportBroadcastMaintenance/SelectRetiLocaliReportToBeValidatedData');
      return response.data;
    },
    async sendCAMReportMail(bodyObj) {
      if(!bodyObj || !bodyObj.ClientName || !bodyObj.MailToList || !bodyObj.MailToList.length)
        throw new Error("Alcuni campi non sono corretti per l'invio della email.");
      let response = await axios.post('/ReportBroadcastMaintenance/SendCAMReportMail', bodyObj);
      return response;
    },
    formatToDoMaintenances(data) {
      let arr = [];
      if(Array.isArray(data)) {
        data.forEach(a => {          
            let arrLoc = [];
            a.Locations.forEach(b => {
              let arrMonths = [];
              b.Months.forEach(c => {
                let arrMaintenances = [];
                c.Maintenances.forEach(m => {
                  arrMaintenances.push({
                    ...m,
                    id: `${m.CustomerName} > ${m.Location} > ${m.MaintenanceDate}`,
                    label: `${m.CustomerName} > ${(m.MaintenanceDate && m.MaintenanceDate.split(" ")[0])}`,
                    type: "file"
                  });
                });
                arrMonths.push({
                  id: `${a.RegionName}/${b.LocationName}/${c.YearString}/${c.MonthString}`,
                  label: `${c.MonthString}/${c.YearString}`,
                  children: arrMaintenances,
                  type: "folder"
                });
              });
              arrLoc.push({
                id: `${a.RegionName}/${b.LocationName}`,
                label: b.LocationName,
                children: arrMonths,
                type: "folder"
              });
            });
            
            arr.push({
              id: `${a.RegionName}`,
              label: a.RegionName,
              children: arrLoc,
              type: "folder"
            });
          });
      }        
      return arr;
    },
    formatHistoricalMaintenances(data) {
        let arr = [];
        if(Array.isArray(data)) {
          data.forEach(a => {          
              let arrLoc = [];
              a.Locations.forEach(b => {
                let arrYears = [];
                b.Years.forEach(c => {
                  let arrMaintenances = [];
                  c.Maintenances.forEach(m => {
                    arrMaintenances.push({
                      ...m,
                      id: `${m.ReportFileName}`,
                      label: `${m.CustomerName} > ${(m.ReportDate && m.ReportDate.split(" ")[0])}`,
                      type: "file"
                    });
                  });
                  arrYears.push({
                    id: `${a.RegionName}/${b.LocationName}/${c.YearName}`,
                    label: c.YearName,
                    children: arrMaintenances,
                    type: "folder"
                  });
                });
                arrLoc.push({
                  id: `${a.RegionName}/${b.LocationName}`,
                  label: b.LocationName,
                  children: arrYears,
                  type: "folder"
                });
              });
              
              arr.push({
                id: `${a.RegionName}`,
                label: a.RegionName,
                children: arrLoc,
                type: "folder"
              });
            });
        }        
        return arr;
    },    
    formatCAMReportMaintenances(data) {
      let arr = [];
      if(Array.isArray(data)) {
        data.forEach(a => {          
          let arrYears = [];
          a.Years.forEach(b => {
            let arrMonths = [];
            b.Months.forEach(c => {
              let arrLastFodlers = [];
              c.LastFolders.forEach(d => {
                let arrReports = [];
                if(d.Report) {
                  arrReports = [{ 
                    Report: d.Report, 
                    type: "file", 
                    id: `${d.LastFolderName}/${d.Report.ReportFileName}`, 
                    label: d.Report.ReportFileName.replace("_report","_" + d.LastFolderName), 
                    CustomerName: a.CustomerName,
                    YearName: b.YearName, 
                    Month: c.Month, 
                    LastFolderName: d.LastFolderName
                  }];
                }
                
                arrLastFodlers.push({
                  id: `${a.CustomerName}/${b.YearName}/${c.Month}/${d.LastFolderName}`,
                  label: d.LastFolderName + '',
                  children: arrReports,
                  type: "folder"
                });

              });
              arrMonths.push({
                id: `${a.CustomerName}/${b.YearName}/${c.Month}`,
                label: c.Month + '',
                children: arrLastFodlers,
                type: "folder"
              });
            });
            arrYears.push({
              id: `${a.CustomerName}/${b.YearName}`,
              label: b.YearName + '',
              children: arrMonths,
              type: "folder"
            });
          });            
          arr.push({
            id: `${a.CustomerName}`,
            label: a.CustomerName,
            children: arrYears,
            type: "folder"
          });
        });
      }        
      return arr;
    },
    formatRetiLocaliReportTobeValidatedReport(data) {
      let arr = [];
      if(Array.isArray(data)) {
        data.forEach(a => {          
          let arrMonths = [];
          a.Months.forEach(b => {
            let arrMuxes = [];
            b.Muxs.forEach(c => {
              let arrLastFodlers = [];
              c.LastFolders.forEach(d => {
                let arrReports = [];
                if(d.Report) {
                  arrReports = [{ 
                    Report: d.Report, 
                    type: "file", 
                    id: `${a.YearName}/${b.MonthName}/${c.MuxName}/${d.LastFolderName}/${d.Report.ReportFileName}`, 
                    label: d.Report.ReportFileName.replace("Report","" + d.LastFolderName), 
                    CustomerName: d.LastFolderName,
                    YearName: a.YearName, 
                    Month: b.MonthName, 
                    LastFolderName: c.MuxName
                  }];
                }
                
                arrLastFodlers.push({
                  id: `${a.YearName}/${b.MonthName}/${c.MuxName}/${d.LastFolderName}`,
                  label: d.LastFolderName + '',
                  children: arrReports,
                  type: "folder"
                });

              });
              arrMuxes.push({
                id: `${a.YearName}/${b.MonthName}/${c.MuxName}`,
                label: c.MuxName + '',
                children: arrLastFodlers,
                type: "folder"
              });
            });
            arrMonths.push({
              id: `${a.YearName}/${b.MonthName}`,
              label: b.MonthName + '',
              children: arrMuxes,
              type: "folder"
            });
          });            
          arr.push({
            id: `${a.YearName}`,
            label: a.YearName,
            children: arrMonths,
            type: "folder"
          });
        });
      }        
      return arr;
    },

    // files calls    
    async downloadCAMReportSingle(reportPath) {         
      let response = await axios.post('/ReportBroadcastMaintenance/DownloadCAMReportSingle', {
        reportPath: reportPath
      }, {
        responseType: 'blob', // important
      });
      let _filename = (response.headers["content-disposition"].split("filename=")[1]).replace(/"/g,"");
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(new Blob([response.data], { type: response.headers['content-type'], encoding: 'UTF-8' }), _filename);
      } else {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'], encoding: 'UTF-8' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', _filename);
        document.body.appendChild(link);
        link.click(); 
      }      
    },
    async validateReport(muxesToBeValidated) {       
      let response = await axios.post('/ReportBroadcastMaintenance/ValidateRetiLocaliReports', JSON.stringify(muxesToBeValidated)
      , { headers: {
        'Content-Type': 'application/json'
      }} 
      , {
        responseType: 'json',
      });
      if(response){
        window.history.go();
      }
    },
    async createCAMReportZipFile(arrPathList) {         
      let response = await axios.post('/ReportBroadcastMaintenance/CreateCAMReportZipFile', {
        CAMReportDownloadModelList: arrPathList
      });
      if(response.data.Success) {
        let _origin = (location.origin.toLocaleLowerCase().includes("augeg4:3033") ? new URL("/", location.origin).href.replace(":3033","") : "/");
        window.open(_origin + "ReportBroadcastMaintenance/DownloadCAMReportZipFile/?fn=" + response.data.FileName + "&ss=RBMToDo")
      }
    },    
    async createZipFileMaintenances(bodydata) {
      let c = await fetch("/ReportBroadcastMaintenance/CreateMaintenanceDoneZipFile", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(bodydata) // body data type must match "Content-Type" header
      });
      let data = await c.json();
      
      if(data.Success) {
          let _origin = (location.origin.toLocaleLowerCase().includes("augeg4:3033") ? new URL("/", location.origin).href.replace(":3033","") : "/");
          window.open(_origin + "ReportBroadcastMaintenance/DownloadMaintenanceDoneZipFile/?fn=" + data.FileName)
      }
  },    
  async createMaintenanceToDoExcel(mantenanceList) {
    let response = await axios.post('/ReportBroadcastMaintenance/CreateMaintenanceToDoExcel', mantenanceList);
    if(response.data.Success) {
        let _origin = (location.origin.toLocaleLowerCase().includes("augeg4:3033") ? new URL("/", location.origin).href.replace(":3033","") : "/");
        window.open(_origin + "ReportBroadcastMaintenance/DownloadMaintenanceToDoExcel/?fn=" + response.data.FileName + "&ss=RBMToDo")
    }
  },  
  async createMaintenanceHistoricalExcel(model) {
    let bodyObj = { reportPath: model.ReportPath, location: model.Location, sedeTecnica: model.SedeTecnica, reportDate: model.ReportDate };    
    let response = await axios.post('/ReportBroadcastMaintenance/DownloadMaintenanceDoneSingle', bodyObj , {
      responseType: 'blob', // important
    });
    let _filename = (response.headers["content-disposition"].split("filename=")[1]).replace(/"/g,"");
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(new Blob([response.data], { type: response.headers['content-type'], encoding: 'UTF-8' }), _filename);
    } else {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'], encoding: 'UTF-8' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', (response.headers["content-disposition"].split("filename=")[1]).replace(/"/g,""));
      document.body.appendChild(link);
      link.click();
    }
  }
};