<template>
  <div class="p-3 bg-white">
    <div class="clearfix">
      <div class="float-right">
        <button type="button" class="btn btn-primary ml-1" v-show="selectedItems.length"
          @click="downloadMaintenances()">
          <i v-show="this.$store.state.appStoreModule.generalFetchingCount" class="fa fa-spinner fa-spin mr-2"></i>scarica <span>({{getSelectedData.length}})</span> <i class="fa fa-download"></i></button>
      </div>
    </div>
    <div v-show="selectedItems.length">
      <h3>Report selezionati</h3>
      <div v-for="(item, index) in getSelectedDataShowed" :key="item.id">
        <div>
            <h4 v-show="!getSelectedDataShowed[index -1] || item.CustomerName !== getSelectedDataShowed[index -1].CustomerName">{{item.CustomerName}}</h4>
            <span>{{item.LastFolderName}}</span>&nbsp;<span>{{item.Month}}/{{item.YearName}}</span>
        </div>
        <table class="table table-sm table-striped">
          <tr>
            <th>&nbsp;</th><th>Cliente</th><th>Periodo</th><th>Mux</th><th>File name</th>
          </tr>
          <tbody>
            <tr v-for="(fileItem, index) in item.Report.ReportFileList" :key="fileItem">
              <td style="width:1%;">{{index + 1}}</td>
              <td style="width:30%;">{{item.CustomerName}}</td>
              <td style="width:10%;">{{item.Month}}/{{item.YearName}}</td>
              <td style="width:10%;">{{item.LastFolderName}}</td>
              <td style="width:50%;" :title="getfileItemName(fileItem, true, false)"><span>{{getfileItemName(fileItem, true, true)}}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
      <button type="button" class="btn btn-secondary w-100" 
        @click="showRecordsLimit = 10 + showRecordsLimit"
        v-show="getSelectedDataShowed.length < getSelectedData.length">
        show more...
      </button>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import maintenanceService from '@/services/maintenanceService';
  import axios from 'axios';

  export default {    
    name: 'AlgRetiLocaliReportsValidatedDetails',
    props: {
      data: { type: Array, default() { return []; } },
      selectedItems: Array
    },
    data() {
      return {
        showUpload: false,
        fetchingUpload: false,
        showSendMail: false,
        fetchingSendMail: false,
        showRecordsLimit: 10,
        clientName: '',

        showSigleSelectedFiles: {}
      };
    },
    mounted(){
      this.showRecordsLimit = 10;
    },
    computed: {
      ...mapState('retiLocaliReportsValidatedMaintenancesModule', {
        'uploadClientList': function (state) {
          if(state.uploadClientList && state.uploadClientList.length === 0) 
            return [];
          this.clientName = state.uploadClientList[0].ClientName;
          return state.uploadClientList; 
        }
      }),
      getSelectedFolders() {
        let dic = {
          "DEFAULT": ["Report"],
          "ELETTRONICA INDUSTRIALE": ["Verbale", "Report da validare", "Report validati"]
        };
        if(dic[this.clientName]) {
          return dic[this.clientName];
        }
        
        return dic["DEFAULT"]; 
      },
      getSelectedMonths() {
        // let ret = this.getAllReportsData.map(a => +a.Month);
        // ret.sort();
        // let beforeMonth = (new Date().getMonth() <= 0) ? 12 : new Date().getMonth();
        // ret = [beforeMonth, ...ret].filter((a, b) => ret.indexOf(a) === b);
        // ret.sort();
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      },
      getSelectedYears() {
        let ret = this.getAllReportsData.map(a => +a.YearName);
        ret.push(new Date().getFullYear());
        ret = ret.filter((a, b) => ret.indexOf(a) === b); // elimino i doppioni
        ret.sort();
        return ret;
      },
      ...mapGetters('appStoreModule', ['canEditCAMReports']),
      getSelectedDataShowed() {
        return this.getSelectedData.slice(0, this.showRecordsLimit);
      },
      getAllReportsData() {
        let arr = [];
        this.data.forEach(a => {
          a.children.forEach(b => {
            b.children.forEach(c => {
              c.children.forEach(d => {
                d.children.forEach(m => {
                  arr.push(m);
                });
              });
            });
          });
        });
        return arr;
      },
      getSelectedData() {
        let recCount = 0;
        let arr = [];
        this.data.forEach(a => {
          a.children.forEach(b => {
            b.children.forEach(c => {
              c.children.forEach(d => {
                d.children.forEach(m => {
                  if(this.selectedItems.includes(m.id)) {
                    if(recCount++ > this.showRecordsLimit)
                      return arr;
                    arr.push(m);
                  }
                });
              });
            });
          });
        });
        return arr;
      }
    },
    methods: {
      isLastMonthYear(c) {
        return +c === (new Date((new Date()).setMonth(new Date().getMonth() - 1)).getFullYear());
      },
      isLastMonth(c) {
        return +c === (new Date((new Date()).setMonth(new Date().getMonth() - 1)).getMonth() + 1);
      },      
      getfileItemName(str, onlyName, slice) {
        if(onlyName) {
          let arr = str.split('\\');
          if(arr[arr.length -1].length > 50 && slice){
            return arr[arr.length -1].slice(0, 50)+"...";
          }
          return arr[arr.length -1];
        }
        if(str[1]=== ':') {
          str = str.split('\\').slice(3).join('/');
        }
        return str;
      },
      toggleDD(id) {
        let _showSigleSelectedFiles = { ...this.showSigleSelectedFiles };
        _showSigleSelectedFiles[id] = !_showSigleSelectedFiles[id];
        this.showSigleSelectedFiles = _showSigleSelectedFiles;
      },
      onSubmitUpload(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        let confirmMsg = "Attenzione: I dati del report del cliente verranno sovrascritti, confermi?";
        if(ev.target.uploadBehavior.value + "" === "1")  {
          confirmMsg = "Attenzione: I file verranno aggiunti nella cartella selezionata, confermi?"
        }
        if(!confirm(confirmMsg))
          return;
        this.fetchingUpload = true;
        let formData = new FormData();        
        var files = this.$refs.uploadFormControl.files;
        for (var x = 0; x < files.length; x++)
        {
            var name = files[x].name; 
            formData.append(name, files[x]);
        }
        formData.append("clientPath", `${ev.target.clientName.value}/${ev.target.yearSel.value}/${ev.target.monthSel.value}/${ev.target.folderSel.value}`);
        formData.append("behaviorUpload", (ev.target.uploadBehavior.value || "1"));

        axios.post('/ReportBroadcastMaintenance/UploadCAMReport?id=securityId', formData, {})
          .then((res) => {
            if(!res.data.Success)
              throw new Error((res.data.statusText || 'Errore upload, non è stato possibile caricare i file.'));
            this.$store.commit("appStoreModule/addAppMessage", { message: "File caricato con successo.", code: 1 });
          })
          .catch((err) => {
            this.$store.commit("appStoreModule/addAppMessage", err);
          }).finally(() => {
            setTimeout(() => {
              this.$store.dispatch("retiLocaliReportsValidatedMaintenancesModule/loadRetiLocaliReportsValidatedMaintenancesTree");
              this.fetchingUpload = false;
              this.showUpload = false;
              this.$refs.uploadFormControl.value = '';
              if(this.$refs.uploadFormControl.value) {
                  this.$refs.uploadFormControl.type = "text";
                  this.$refs.uploadFormControl.type = "file";
              }
            },200);
          });
      },
      async downloadMaintenances() {
        try {
          if(this.getSelectedData.length === 0) {
            alert("Selezionare un file");
            return;
          }
          this.$store.commit("appStoreModule/startFetching");
          if(this.getSelectedData.length > 1 || this.getSelectedData[0].Report.ReportFileList.length > 1) {
            let arrPathList = [];
            arrPathList = this.getSelectedData.map(a => { return { ReportPathList: a.Report.ReportFileList } });
            await maintenanceService.createCAMReportZipFile(arrPathList);
          } else if(this.getSelectedData.length === 1) { 
            await maintenanceService.downloadCAMReportSingle(this.getSelectedData[0].Report.ReportFileList[0]);
          }
        } catch(err) {
          this.$store.commit("appStoreModule/addAppMessage", err);
        } finally {          
          this.$store.commit("appStoreModule/endFetching");  
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .alg-select-details {
    width: 300px;
    overflow: hidden;
  }
</style>
