let state = {
  generalFetchingCount: 0,
  appMessages: [],
  authInfo: null
};
let getters = {
  itIsLoading(state, getters, rootState) {
    return !!(state.generalFetchingCount || rootState.historicalMaintenancesModule.fetchingCount || rootState.toDoMaintenancesModule.fetchingCount || rootState.monthlyReportsMaintenancesModule.fetchingCount || rootState.retiLocaliReportsValidatedMaintenancesModule.fetchingCount  || rootState.retiLocaliReportsToBeValidatedMaintenancesModule.fetchingCount);
  },
  canAccessAtReportBroadcastMaintenance(state) {
    return !!state.authInfo && state.authInfo.sub.roles.includes("TT22ReportBroadcastMaintenance001ManageRBM");
  },
  canManageHistorical(state) {
    return !!state.authInfo && state.authInfo.sub.roles.includes("TT22ReportBroadcastMaintenance002ManageMaintenanceDone");
  },
  canManageToDo(state) {
    return !!state.authInfo && state.authInfo.sub.roles.includes("TT22ReportBroadcastMaintenance003ManageMaintenanceToDo");
  },
  canManageReportsCAM(state) {
    return !!state.authInfo && state.authInfo.sub.roles.includes("TT22ReportBroadcastMaintenance004ManageCAMReports");
  },
  canEditCAMReports(state) {
    return !!state.authInfo && state.authInfo.sub.roles.includes("TT22ReportBroadcastMaintenance004EditCAMReports");
  },
  canViewRetiLocaliReports(state) {
    return !!state.authInfo && state.authInfo.sub.roles.includes("TT22ReportBroadcastMaintenance005ViewRetiLocaliReports");
  },
  canValidateRetiLocaliReports(state) {
    return !!state.authInfo && state.authInfo.sub.roles.includes("TT22ReportBroadcastMaintenance006ValidateRetiLocaliReports");
  }
};
let mutations = {
  authInfo(state, authInfo) {
    state.authInfo = authInfo;
  },
  startFetching(state) {
    state.generalFetchingCount = state.generalFetchingCount + 1;
  },
  endFetching(state) {
    state.generalFetchingCount = state.generalFetchingCount - 1;
  },
  addAppMessage(state, { message, code } = { message: 'error', code: 4000 }) {
    state.appMessages = [...state.appMessages, { message, code }];
  },
  clearAppMessages(state) {
    state.appMessages = [];
  }
};
let actions = {};
let modules = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules
}