export default {
    getChildrenIdRecursion(item, outIds = null) {
        outIds || (outIds = {});
        outIds[item.id] = 1;
        if(!item.children || !item.children.length || item.children.length === 0)
            return Object.keys(outIds);
        for(let el of item.children) {
            outIds[el.id] = 1;
            this.getChildrenIdRecursion(el, outIds);
        }
        return Object.keys(outIds);
    }
}