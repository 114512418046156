<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-4 pl-0">          
        <i v-show="fetchingCount" class="fa fa-spinner fa-spin"></i>
        <AlgTreeSelector :data="historicalMaintenancesTree" v-model="selectedTree" />
      </div>
      <div class="col-8">
        <AlgSelectHistoricalDetails :data="historicalMaintenancesTree" :selected-items="selectedTree" />
      </div>
    </div>
  </div>
</template>

<script>
import AlgTreeSelector from '@/components/tree/AlgTreeSelector.vue';
import AlgSelectHistoricalDetails from '@/components/historicalMaintenance/AlgSelectHistoricalDetails.vue';
import { mapState } from 'vuex';

export default {
  name: 'histoicalMaintenanceView',
  components: {
    AlgTreeSelector,
    AlgSelectHistoricalDetails
  },
  data() {
    return  {
      selectedTree: []
    };
  },
  computed: {
     ...mapState('historicalMaintenancesModule', {
        'historicalMaintenancesTree': state => state.historicalMaintenancesTree,
        'fetchingCount': state => state.fetchingCount
      })
  },
  methods: {
    async getManutenzioni() {         
      setTimeout(() => {
        this.$store.dispatch("historicalMaintenancesModule/loadDoneMaintenancesTree");  
      }, 100);
    }
  },
  mounted() {
    this.getManutenzioni()
  }
}
</script>
