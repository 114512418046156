
import maintenanceService from '@/services/maintenanceService';

let state = { 
    retiLocaliReportsToBeValidatedMaintenancesTree: [],
    uploadClientList:[],
    lasatUpdateTreeDate: null,
    fetchingCount: 0
};
let mutations= {
    uploadClientList(state, uploadClientList) {
        state.uploadClientList = uploadClientList;
    },
    retiLocaliReportsToBeValidatedMaintenancesTree(state, retiLocaliReportsToBeValidatedMaintenancesTree) {
        state.retiLocaliReportsToBeValidatedMaintenancesTree = retiLocaliReportsToBeValidatedMaintenancesTree;
    },
    retiLocaliReportsToBeValidatedMaintenancesTreeUpdated(state) {
        state.lasatUpdateTreeDate = new Date();
    },
    startFetching(state) {
        state.fetchingCount = state.fetchingCount + 1;
    },
    endFetching(state) {
        state.fetchingCount = state.fetchingCount - 1;
    }
};
let actions = {
    async loadRetiLocaliReportsToBeValidatedMaintenancesTree(context) {
        try { 
            if(context.state.lasatUpdateTreeDate && (new Date() - context.state.lasatUpdateTreeDate) / 1000 < 10)
                return; 
            context.commit("startFetching");
            
            let data = [];
            data = await maintenanceService.selectRetiLocaliReportToBeValidatedData();
            if(data.Tree) {
                context.commit('retiLocaliReportsToBeValidatedMaintenancesTree', maintenanceService.formatRetiLocaliReportTobeValidatedReport(data.Tree.Years));
                context.commit('uploadClientList', data.SendMailViewResult.UploadClientList);
                context.commit('retiLocaliReportsToBeValidatedMaintenancesTreeUpdated');
            } else {                
                context.commit("appStoreModule/addAppMessage", { message: 'Reports non presenti', code: 1}, { root: true });
            }
        } catch (e) {
            context.commit("appStoreModule/addAppMessage", e, { root: true });
        }
        context.commit("endFetching");
    }
};

export default {
    namespaced : true,
    state,
    mutations,
    actions
}