<template>
  <div style="position:relative;" v-if="appMessages && appMessages.length > 0">
    <button style="position:absolute;z-index:1;top:2px;right:2px;" class="btn btn-light btn-sm" type="button" @click="clearMessages()"><i class="fa fa-times"></i></button>
    <div class="alert" :class="getMessClass(mess)" v-for="(mess, index) in appMessages" :key="index">{{mess.message}}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'AlgAppMessages',
  computed: {
    messageCode() {
      return {
        info: 1,
        warning: 2,
        success: 3,
        genericError: 4000,
        1: 'info',
        2: 'warning',
        3: 'success',
        4000: 'genericError'
      }
    },
    ...mapState('appStoreModule', {
      'appMessages': state => state.appMessages,
    })
  },
  methods: {
    clearMessages() {
      this.$store.commit('appStoreModule/clearAppMessages');
    },
    getMessClass(mess) {
      let c = {};
      if(!mess.code || mess.code >= 4000) {
        c["alert-danger"] = 1;
      }
      if(mess.code === 1) {
        c["alert-info"] = 1;
      }
      if(mess.code === 2) {
        c["alert-warning"] = 1;
      }
      if(mess.code === 3) {
        c["alert-success"] = 1;
      }
      return Object.keys(c);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  
</style>
