<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-4 pl-0">
        <i v-show="fetchingCount" class="fa fa-spinner fa-spin"></i>
        <AlgTreeSelector :data="retiLocaliReportsToBeValidatedMaintenancesTree" v-model="selectedTree" />
      </div>
      <div class="col-8">
        <AlgRetiLocaliReportsToBeValidatedDetails :data="retiLocaliReportsToBeValidatedMaintenancesTree" :selected-items="selectedTree" />
      </div>
    </div>
  </div>
</template>

<script>
import AlgTreeSelector from '@/components/tree/AlgTreeSelector.vue';
import AlgRetiLocaliReportsToBeValidatedDetails from '@/components/retiLocaliReportsToBeValidated/AlgRetiLocaliReportsToBeValidatedDetails.vue';
import { mapState } from 'vuex';

export default {
  name: 'monthlyReportsView',
  components: {
    AlgTreeSelector,
    AlgRetiLocaliReportsToBeValidatedDetails
  },
  data() {
    return {
      selectedTree: []
    };
  },
  mounted() {
    setTimeout(() => {
      this.retiLocaliReportsToBeValidate();
    }, 100);
  },
  computed: {
     ...mapState('retiLocaliReportsToBeValidatedMaintenancesModule', {
        'fetchingCount': state => state.fetchingCount,
        'uploadClientList': state => state.uploadClientList
      }),
      retiLocaliReportsToBeValidatedMaintenancesTree() {
        return this.$store.state.retiLocaliReportsToBeValidatedMaintenancesModule.retiLocaliReportsToBeValidatedMaintenancesTree;
      }
  },
  methods: {
    async retiLocaliReportsToBeValidate() {
      this.$store.dispatch("retiLocaliReportsToBeValidatedMaintenancesModule/loadRetiLocaliReportsToBeValidatedMaintenancesTree");
    },
    getAllItemIds(tree) {
      if(!tree || !tree.length || tree.length === 0)
        return [];
      let arr = [];
      tree.forEach(a => {
        arr.push(a.id);
        a.children.forEach(b => {
          arr.push(b.id);
          b.children.forEach(c => {
            arr.push(c.id);
            c.children.forEach(m => {
              arr.push(m.id);
            });
          });
        });
      });
      return arr;
    }
  }
}
</script>
