<template>
  <div class="p-3 bg-white" v-show="selectedItems.length">
    <div class="float-right">
      <button type="button" class="btn btn-primary" @click="downloadMaintenances()">
        <i v-show="this.$store.state.appStoreModule.generalFetchingCount" class="fa fa-spinner fa-spin mr-2"></i>scarica <span>({{getSelectedData.length}})</span> <i class="fa fa-download"></i></button>
    </div>
    <h3>Manutenzioni selezionate</h3>
    <table class="table table-sm table-striped">
      <tr>
        <th>&nbsp;</th><th>Cliente</th><th>Postazione</th><th>Data</th>
      </tr>
      <tbody>
        <tr v-for="(item, index) in getSelectedDataShowed" :key="item.id">
          <td>
            <i class="fa fa-exclamation-circle" v-show="item.Anomalies"></i>
            {{index + 1}}
          </td>
          <td>{{item.CustomerName}}</td>
          <td>{{item.Location}}</td>
          <td><span style="white-space: nowrap;">{{(item.ReportDate && item.ReportDate.split(" ")[0])}}</span></td>
        </tr>
      </tbody>
    </table>
    <button type="button" class="btn btn-secondary w-100" 
      @click="showRecordsLimit = 10 + showRecordsLimit"
      v-show="getSelectedDataShowed.length < getSelectedData.length">
      show more...
    </button>
  </div>
</template>

<script>
  import maintenanceService from '@/services/maintenanceService';
  export default {    
    name: 'AlgSelectHistoricalDetails',
    props: {
      data: { type: Array, default() { return []; } },
      selectedItems: Array
    },
    data() {
      return {
        showRecordsLimit: 10
      };
    },
    mounted(){
      this.showRecordsLimit = 10;
    },
    computed: {
      getSelectedDataShowed() {
        return this.getSelectedData.slice(0, this.showRecordsLimit);
      },
      getSelectedData() {
        let arr = [];
        this.data.forEach(a => {
          a.children.forEach(b => {
            b.children.forEach(c => {
              c.children.forEach(m => {
                this.selectedItems.includes(m.id) && arr.push(m);
              });
            });
          });
        });
        return arr;
      }
    },
    methods: {
      async downloadMaintenances() {
        try {
          this.$store.commit("appStoreModule/startFetching");
          if(this.getSelectedData.length > 1) {
            // go zip
            let bodydata = {
              "DownloadAttachmentModelList":[]
            }
            for( let el of this.getSelectedData) {
              bodydata.DownloadAttachmentModelList.push({
                  "Location": el.Location,
                  "CustomerName": el.CustomerName,
                  "SedeTecnica": el.SedeTecnica,
                  "ReportDate": el.ReportDate,
                  "ReportPath": el.ReportPath,
                  "ReportFileName": el.ReportFileName,
                  "AttachmentExists": el.AttachmentExists
                });
            }
            await maintenanceService.createZipFileMaintenances(bodydata); 
          } else if(this.getSelectedData.length === 1) {
              let el = this.getSelectedData[0];
              await maintenanceService.createMaintenanceHistoricalExcel({
                  "Location": el.Location,
                  "CustomerName": el.CustomerName,
                  "SedeTecnica": el.SedeTecnica,
                  "ReportDate": el.ReportDate,
                  "ReportPath": el.ReportPath,
                  "ReportFileName": el.ReportFileName,
                  "AttachmentExists": el.AttachmentExists
                });
          }  
        } catch(err) {
          this.$store.commit("appStoreModule/addAppMessage", err);
        } finally {          
          this.$store.commit("appStoreModule/endFetching");  
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .alg-select-details {
    width: 300px;
    overflow: hidden;
  }
</style>
