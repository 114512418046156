<template>
  <div class="alert alert-light">
    <form @submit="onChangePasswordSubmit($event)">
      <div style="width:60%;">
        <div class="form-group">
          <label>Vecchia password</label>
          <input name="oldPassword" class="form-control" type="password" />
        </div>
        <div class="form-group">
          <label>Nuova password</label>
          <input name="newPassword1" class="form-control" type="password" />
        </div>
        <div class="form-group">
          <label>Ripeti password</label>
          <input name="newPassword2" class="form-control" type="password" />
        </div>
        <div class="clearfix">
          <button class="btn btn-primary" type="submit">Cambia password</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'changePasswordView',
  data() {
    return  {};
  },
  methods: {
    async onChangePasswordSubmit(event) {
      event.preventDefault();
      event.stopPropagation();
      try {
        let oldPassword = event.target.oldPassword.value;
        let newPassword1 = event.target.newPassword1.value;
        let newPassword2 = event.target.newPassword2.value;
        if(!oldPassword) {
          throw Error("Inserire la vecchia password");
        }
        if(!newPassword1 || !newPassword2) {
          throw Error("Inserire la password nuova");
        }
        if(newPassword1 != newPassword2) {
          throw Error("Le password nuove non sono uguali");
        }


        newPassword1 = newPassword1.replace(/’/g, "'");
        newPassword2 = newPassword2.replace(/’/g, "'");

        //let r = 
        await axios.post(`/TicketManagement/UserPasswordChange`, {oldPass :oldPassword, newPass: newPassword1});
        // console.log(r);
        alert("password cambiata correttamente.");
      } catch (e) {
          this.$store.commit("appStoreModule/addAppMessage", e);
      }
    }
  }
}

/*

try {
    if(context.state.lasatUpdateTreeDate && (new Date() - context.state.lasatUpdateTreeDate) / 1000 < 10)
        return; 
    context.commit("startFetching");
    let data = await maintenanceService.getHistoricalMaintenances();
    context.commit('historicalMaintenancesTree', data);
    context.commit('toDoMaintenancesTreeUpdated');
} catch (e) {
    context.commit("appStoreModule/addAppMessage", e, { root: true });
}


*/
</script>
