<template>
  <div class="alg-tree-selector">
    <div class="clearfix mb-3"> 
      <button type="button" v-show="value.length > 0" @click="deselezionaTutto()" class="btn btn-secondary btn-sm float-right">
        <i class="fa" :class="{'fa-square': value.length === 0, 'fa-minus-square': value.length > 0}"></i> Reset</button>
      <input type="text" placeholder="cerca..." style="width:60%;height: 29px;" class="form-control" @keyup="onFilterTextChange($event)" @change="onFilterTextChange($event)" />
    </div>
    <div v-for="item in dataFiltered" :key="item.id">
      <AlgTreeSelectorItem 
        :items-checked="value" :items-selected="itemsSelected" :item="item" v-model="value" 
        @check-click="onChangeCheck" @select-click="onSelectItem" />
    </div>
  </div>
</template>

<script>
  import AlgTreeSelectorItem from '@/components/tree/AlgTreeSelectorItem.vue';
  import treeUtils from '@/components/tree/treeUtils';
  
  export default {    
    name: 'AlgTreeSelector',
    components: {
      AlgTreeSelectorItem
    },
    props: {
      data: { type: Array, default() { return []; } },
      value: Array,
      level: Number
    },
    data() {
      return {
        searchText: '',
        itemsSelected: []
      };
    },
    computed: {
      dataFiltered() {
        let tempTree = JSON.parse(JSON.stringify(this.data));
        if(!this.searchText)
          return tempTree;
        
        return this.filterData(tempTree);
      }
    },
    methods: {
      onFilterTextChange(ev) {
        clearTimeout(window.filterSearchTimer);
        window.filterSearchTimer = setTimeout(() => {
          this.searchText = ev.target.value;
        }, 800);
      },
      deselezionaTutto() {
        this.itemsSelected = [];
        this.$emit("input", []);
      },
      filterData(treeModel) {
        if(!treeModel || !Array.isArray(treeModel) || treeModel.length === 0)
          return [];
        this.filterDataRecursive(treeModel);
        return treeModel;
      },      
      filterDataRecursive(treeModel) {
        if(!treeModel || !Array.isArray(treeModel) || treeModel.length === 0)
          return false;
        for(let n of treeModel) {
          n.__isFiltered = (this.filterDataRecursive(n.children) || (n.label+'').toLowerCase().includes(this.searchText));
        }
        return treeModel.some(s => s.__isFiltered);
      },
      onChangeCheck(val, item) {
        let _itemEls = treeUtils.getChildrenIdRecursion(item);
        
        let _v = [];
        if(val) {
          _v = [...this.value, ..._itemEls];
        } else {
          _v = this.value.filter(id => !_itemEls.includes(id));
        }
        this.$emit("input", _v);
      },      
      onSelectItem(val, item) {
        if(val) {
          this.itemsSelected = [...this.itemsSelected, item.id];
        } else {
          this.itemsSelected = this.itemsSelected.filter(f => f !== item.id);
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .alg-tree-selector {
    width: 100%;
    overflow: hidden;
  }
</style>
