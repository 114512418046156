// prototype Date
Date.prototype.formatStr = function(hideTime= false) { 
    if(hideTime) {
        return this.toLocaleDateString("it-IT", { day: "2-digit", month: "2-digit", year: "numeric"});
    } else {
        return this.toLocaleString("it-IT", { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit" });
    }
}
Date.prototype.toISOLocalString = function() { 
    return new Date(this - this.getTimezoneOffset()*60000).toISOString().slice(0, -1);    
}
Date.prototype.addDays = function(numDays) {
    if(!numDays)
        return this;

    let _date = new Date(this);
    _date.setTime(_date.getTime() + (numDays * 24 * 60 * 60 * 1000));
    return _date;
}
Date.prototype.createDateAtZeroTime = function(s_date = null) {
    let d = null;
    if(!s_date)
        d = new Date();
    else
        d = new Date(s_date);
    if(isNaN(d))
        return null;
    d.setHours(0,0,0,0);
    return d;
}
