<template>
  <div id="app" class="p-3" v-show="authInfo">
    <div class="header">
      <div v-if="authInfo" class="float-right pt-2">
        <div class="dropdown" :class="{ show: openUserMenu }">
          <button
            class="btn btn-primary dropdown-toggle"
            type="button"
            v-click-outside="closeUserMenu"
            @click="openUserMenu = !openUserMenu"
          >
            <i class="fa fa-user"></i> {{ authInfo.sub.name }}&nbsp;
          </button>
          <div
            class="dropdown-menu dropdown-menu-right"
            :class="{ show: openUserMenu }"
          >
            <a
              href="#"
              class="dropdown-item nav-link"
              @click="logoutApp($event)"
              >Logout <i class="fa fa-sign-out-alt"></i
            ></a>
            <router-link class="dropdown-item nav-link" to="/change-password"
              >Cambia password <i class="fa fa-unlock-alt"></i
            ></router-link>
          </div>
        </div>
      </div>
      <img
        alt="EITowers logo"
        class="mb-2"
        width="200px"
        src="eitowers-logo.png"
      />
    </div>
    <AlgAppMessages />
    <h1>
      Portale dati clienti
      <i v-show="itIsLoading" class="fa fa-spinner fa-spin"></i>
    </h1>
    <div style="min-height: 400px">
      <div>
        <div class="nav nav-tabs alg-secondary-tabs" v-if="authInfo">
          <li v-show="canManageReportsCAM" class="nav-item mr-1">
            <router-link class="nav-link" to="/monthly-reports"
              >Report mensili</router-link
            >
          </li>
          <li v-show="canManageHistorical" class="nav-item mr-1">
            <router-link class="nav-link" to="/historical-maintenances"
              >Storico manutenzioni effettuate</router-link
            >
          </li>
          <li v-show="canManageToDo" class="nav-item mr-1">
            <router-link class="nav-link" to="/maintenances"
              >Manutenzioni da effettuare</router-link
            >
          </li>
          <li v-show="canViewRetiLocaliReports" class="nav-item mr-1">
            <router-link class="nav-link" to="/reti-locali-validated"
              >Report Reti locali</router-link
            >
          </li>
          <li v-show="canValidateRetiLocaliReports" class="nav-item mr-1">
            <router-link class="nav-link" to="/reti-locali-to-be-validated"
              >Report reti locali da validare</router-link
            >
          </li>
        </div>
        <div class="content-fluid bg-light p-3">
          <router-view />
        </div>
      </div>
    </div>
    <div class="clearfix pt-3 pb-3">
      <span class="nav float-right"
        >Copyright © 2012-2024 EI Towers. Tutti i diritti riservati</span
      >
      <ul class="nav float-left">
        <li><a href="https://www.eitowers.it/privacy-policy/">Privacy</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import AlgAppMessages from "@/components/master/AlgAppMessages.vue";
import maintenanceService from "@/services/maintenanceService";

export default {
  data() {
    return {
      openUserMenu: false,
    };
  },
  components: {
    AlgAppMessages,
  },
  computed: {
    ...mapState("appStoreModule", {
      generalFetchingCount: (state) => state.generalFetchingCount,
      appMessages: (state) => state.appMessages,
      authInfo: (state) => state.authInfo,
    }),
    ...mapGetters(
      "appStoreModule",
      [
        "itIsLoading",
        "canManageHistorical",
        "canManageToDo",
        "canManageReportsCAM",
        "canViewRetiLocaliReports",
        "canValidateRetiLocaliReports",
      ],
      { root: true }
    ),
  },
  methods: {
    async logoutApp(event) {
      event.preventDefault();
      event.stopPropagation();
      await maintenanceService.AUTH.doLogout();
      let _origin = location.origin.toLocaleLowerCase().includes("augeg4:3033")
        ? new URL("/", location.origin).href.replace(":3033", "")
        : "/";
      window.location.href = _origin + "G4/";
    },
    closeUserMenu() {
      this.openUserMenu = false;
    },
  },
};
</script>

<style>
#app {
  background-color: #fff;
  color: #505050;
  width: 1200px;
  border: 1px solid #a0a0a0;
  position: relative;
  margin: auto;
}
@media (min-width: 1400px) {
  body #app {
    width: 1400px;
  }
}
body {
  background-color: #3575bd;
  color: #fff;
  padding-top: 24px;
}
.nav-tabs.alg-secondary-tabs .nav-item {
  margin-top: 0;
  margin-right: 1px;
}
.nav-tabs.alg-secondary-tabs .nav-item .nav-link {
  background-color: #ecf0f1;
  border-bottom-color: #fff;
  color: #2c3e50;
  opacity: 0.7;
  transition: opacity 300ms;
}
.nav-tabs.alg-secondary-tabs .nav-item .nav-link:hover {
  opacity: 1;
}
.nav-tabs.alg-secondary-tabs .nav-item .nav-link.router-link-exact-active {
  background-color: #ecf0f1;
  border-bottom-color: #ecf0f1;
  color: #18bc9c;
  opacity: 1;
}
</style>
