import Vue from 'vue'
import Vuex from 'vuex'

import historicalMaintenancesModule from '@/store/modules/historicalMaintenances.module'
import toDoMaintenancesModule from '@/store/modules/toDoMaintenances.module'
import monthlyReportsMaintenancesModule from '@/store/modules/monthlyReportsMaintenances.module'
import retiLocaliReportsToBeValidatedMaintenancesModule from '@/store/modules/retiLocaliReportsToBeValidatedMaintenances.module'
import retiLocaliReportsValidatedMaintenancesModule from '@/store/modules/retiLocaliReportsValidatedMaintenances.module'
import appStoreModule from '@/store/modules/appStore.module'
import { version } from '../../package.json'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    appStoreModule,
    historicalMaintenancesModule,
    monthlyReportsMaintenancesModule,
    retiLocaliReportsToBeValidatedMaintenancesModule,
    retiLocaliReportsValidatedMaintenancesModule,
    toDoMaintenancesModule,
    version
  }
})

