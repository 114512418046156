
import maintenanceService from '@/services/maintenanceService';

let state = { 
    retiLocaliReportsValidatedMaintenancesTree: [],
    uploadClientList:[],
    lasatUpdateTreeDate: null,
    fetchingCount: 0
};
let mutations= {
    uploadClientList(state, uploadClientList) {
        state.uploadClientList = uploadClientList;
    },
    retiLocaliReportsValidatedMaintenancesTree(state, retiLocaliReportsValidatedMaintenancesTree) {
        state.retiLocaliReportsValidatedMaintenancesTree = retiLocaliReportsValidatedMaintenancesTree;
    },
    retiLocaliReportsValidatedMaintenancesTreeUpdated(state) {
        state.lasatUpdateTreeDate = new Date();
    },
    startFetching(state) {
        state.fetchingCount = state.fetchingCount + 1;
    },
    endFetching(state) {
        state.fetchingCount = state.fetchingCount - 1;
    }
};
let actions = {
    async loadRetiLocaliReportsValidatedMaintenancesTree(context) {
        try { 
            if(context.state.lasatUpdateTreeDate && (new Date() - context.state.lasatUpdateTreeDate) / 1000 < 10)
                return; 
            context.commit("startFetching");
            
            let data = [];
            data = await maintenanceService.selectRetiLocaliReportValidatedData();
            if(data.Tree) {
                context.commit('retiLocaliReportsValidatedMaintenancesTree', maintenanceService.formatCAMReportMaintenances(data.Tree.Customers));
                context.commit('uploadClientList', data.SendMailViewResult.UploadClientList);
                context.commit('retiLocaliReportsValidatedMaintenancesTreeUpdated');
            } else {                
                context.commit("appStoreModule/addAppMessage", { message: 'Reports non presenti', code: 1}, { root: true });
            }
        } catch (e) {
            context.commit("appStoreModule/addAppMessage", e, { root: true });
        }
        context.commit("endFetching");
    }
};

export default {
    namespaced : true,
    state,
    mutations,
    actions
}