import maintenanceService from '@/services/maintenanceService';

let state = { 
    historicalMaintenancesTree: [],
    lasatUpdateTreeDate: null,
    fetchingCount: 0
};
let mutations= {
    historicalMaintenancesTree(state, historicalMaintenancesTree) {
        state.historicalMaintenancesTree = historicalMaintenancesTree;
    },
    toDoMaintenancesTreeUpdated(state) {
        state.lasatUpdateTreeDate = new Date();
    },
    startFetching(state) {
        state.fetchingCount = state.fetchingCount + 1;
    },
    endFetching(state) {
        state.fetchingCount = state.fetchingCount - 1;
    }
};
let actions = {
    async loadDoneMaintenancesTree(context) {
        try {
            if(context.state.lasatUpdateTreeDate && (new Date() - context.state.lasatUpdateTreeDate) / 1000 < 10)
                return; 
            context.commit("startFetching");
            let data = await maintenanceService.getHistoricalMaintenances();
            context.commit('historicalMaintenancesTree', data);
            context.commit('toDoMaintenancesTreeUpdated');
        } catch (e) {
            context.commit("appStoreModule/addAppMessage", e, { root: true });
        }
        context.commit("endFetching");
    }
};

export default {
    namespaced : true,
    state,
    mutations,
    actions
}