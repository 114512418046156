import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store/index'
import maintenanceService from '@/services/maintenanceService';

import notPermittedView from '../views/notPermitted.view.vue';

import histoicalMaintenanceView from '../views/histoicalMaintenance.view.vue';
import toDoMaintenanceView from '../views/toDoMaintenance.view.vue';
import monthlyReportsView from '../views/monthlyReports.view.vue';
import retiLocaliReportsValidatedView from '../views/retiLocaliReportsValidated.view.vue';
import retiLocaliReportsToBeValidatedView from '../views/retiLocaliReportsToBeValidated.view.vue';
import changePasswordView from '../views/changePassword.view.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'not-permitted',
    component: notPermittedView
  },
  {
    path: '/historical-maintenances',
    name: 'historicalmaintenances',
    component: histoicalMaintenanceView
  },
  {
    path: '/maintenances',
    name: 'maintenances',
    component: toDoMaintenanceView
  },
  {
    path: '/monthly-reports',
    name: 'monthlyreports',
    component: monthlyReportsView
  },
  {
    path: '/reti-locali-validated',
    name: 'retilocalivalidated',
    component: retiLocaliReportsValidatedView
  },
  {
    path: '/reti-locali-to-be-validated',
    name: 'retilocalitobevalidated',
    component: retiLocaliReportsToBeValidatedView
  },
  {
    path: '/change-password',
    name: 'changePassword',
    component: changePasswordView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(async (to, from, next) => {
  if(!store.state.appStoreModule.authInfo) {
    try {
      let response = await maintenanceService.AUTH.getAuthUserJWT();
      if(response && response.data) {
        let obj = maintenanceService.AUTH.parseJwt(response.data.jwt);
        store.commit('appStoreModule/authInfo', obj);
      } else {
        throw Error("Utente non loggato.");
      }
    } catch (err) {
      store.commit("appStoreModule/addAppMessage",err);
    }
  }

  

  let selectedRoute = "";
  switch(to.name + "") {
    case "historicalmaintenances": {
      if(!store.getters["appStoreModule/canManageHistorical"]) {
        store.commit("appStoreModule/addAppMessage", { message: "Non hai i permessi per accedere in questa sezione" });
        selectedRoute = "/login";
      }
      break;
    }
    case "maintenances": {
      if(!store.getters["appStoreModule/canManageToDo"]) {
        store.commit("appStoreModule/addAppMessage", { message: "Non hai i permessi per accedere in questa sezione" });
        selectedRoute = "/login";
      }
      break;
    }
    case "monthlyreports": {
      if(!store.getters["appStoreModule/canManageReportsCAM"]) {
        store.commit("appStoreModule/addAppMessage", { message: "Non hai i permessi per accedere in questa sezione" });
        selectedRoute = "/login";
      }
      break;
    }
    case "retilocalivalidated": {
      if(!store.getters["appStoreModule/canViewRetiLocaliReports"]) {
        store.commit("appStoreModule/addAppMessage", { message: "Non hai i permessi per accedere in questa sezione" });
        selectedRoute = "/login";
      }
      break;
    }
    case "retilocalitobevalidated": {
      if(!store.getters["appStoreModule/canValidateRetiLocaliReports"]) {
        store.commit("appStoreModule/addAppMessage", { message: "Non hai i permessi per accedere in questa sezione" });
        selectedRoute = "/login";
      }
      break;
    }
    case "not-permitted": {
      if(store.getters["appStoreModule/canManageReportsCAM"]) {
        selectedRoute = "/monthly-reports";
      } else if(store.getters["appStoreModule/canManageHistorical"]) {
        selectedRoute = "/historical-maintenances";
      } else if(store.getters["appStoreModule/canManageToDo"]) {
        selectedRoute = "/maintenances";
      } else if(store.getters["appStoreModule/canViewRetiLocaliReports"]) {
        selectedRoute = "/reti-locali-validated";
      } else if(store.getters["appStoreModule/canValidateRetiLocaliReports"]) {
        selectedRoute = "/reti-locali-validated";
      }
      break;
    }
  }

  if(selectedRoute)
    next(selectedRoute);
  else
    next();
})
export default router
