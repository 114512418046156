<template>
  <div class="alg-tree-selector-item" :class="{'active': selected, 'filtered': item.__isFiltered}" :title="item.label"
       v-show="isParentSelected">
    <div>
      <i class="fa pr-1 canClick" v-show="item.type=== 'folder'" @click="onSelectItem(!selected, item)" :class="{ 'fa-caret-down': selected, 'fa-caret-right': !selected }"></i>
      <i class="fa mr-1 canClick" :class="{'fa-check-square': checked, 'fa-square': !checked}" @click="onChangeCheck(!checked, item)"></i>
      <span class="canClick alg-tree-selector-item-label" @click="onSelectItem(!selected, item)"
        :class="{'alg-strong-and-italics':hasAnomalies}">
        <i class="fa mr-1" :class="[iconType]"></i>
        <i class="fa fa-exclamation-circle mr-1 ml-1" title="Con anomalia" v-show="hasAnomalies"></i>{{item.label}}<span></span></span>
    </div>
    <div v-if="item.children && item.children.length > 0 && selected" class="pl-3">
      <AlgTreeSelectorItem 
        v-for="itemChild in item.children" :key="itemChild.id" 
        :items-checked="itemsChecked" :items-selected="itemsSelected" :item="itemChild" 
        :open-state="itemsSelected" :level="level+1" :is-parent-selected="selected" :is-parent-checked="checked" 
         @check-click="onChangeCheck" @select-click="onSelectItem" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlgTreeSelectorItem',
  props: {
    item: Object, // { id: '', label: '', children: [ Object ]},
    itemsChecked: { type: Array, default() { return []; } },
    itemsSelected: { type: Array, default() { return []; } },
    level: { type:Number, default: 0 },
    isParentSelected: { type: Boolean, default: true },
    isParentChecked: { type: Boolean, default: false }
  },
  computed: {
      iconType() {        
        if(this.item.type === "file") {
          return "fa-file"
        }
        if(this.item.type === "leaf") {
          return "fa-leaf"
        }
        if(this.selected) {
            if(this.item.type === "folder") {
              return "fa-folder-open"
            }
        } else {
            if(this.item.type === "folder") {
              return "fa-folder"
            }
        }
        return "";
      },
      hasAnomalies() {
        return !!(this.item.Anomalies)
      },
      checked() {
        return (this.itemsChecked.includes(this.item.id));
      },
      selected() {
        return this.item.__isFiltered || (this.itemsSelected.includes(this.item.id));
      }
  },
  watch: {
    itemsChecked(val) {
      if(val && val.length && val.length !== 0) {
        if(this.checked && this.item.children && this.item.children.length && !this.item.children.some(s => val.includes(s.id))) {
          this.$emit("check-click", false, this.item);
        } else if(!this.checked && this.item.children && this.item.children.length && !this.item.children.some(s => !val.includes(s.id))) {
          this.$emit("check-click", true, this.item);
        }
      }
    }
  },
  methods: {
    onSelectItem(val, item) {
      this.$emit("select-click", val, item);
    },
    onChangeCheck(val, item) {
      this.$emit("check-click", val, item);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .alg-tree-selector-item {
    /*background-color: white;*/
    font-size: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .alg-strong-and-italics{
    font-weight: bold;
    font-style: italic;
  }
  .canClick {
    cursor: pointer;
  }
  .alg-tree-selector-item.filtered > div > .alg-tree-selector-item-label {
    background-color: #a1a9ca;
  }
  .alg-tree-selector-item-label {
    white-space: normal;
    display: inline-block;
    vertical-align: top;
    width: 95%;
  }
  .alg-tree-selector-item-label > i {
    float: left;
    display: block;
    line-height: 20px;
  }
</style>
