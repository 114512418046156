<template>
  <div class="alert alert-danger">Not permitted.</div>
</template>

<script>
export default {
  name: 'notPermittedView',
  data() {
    return  {};
  }
}
</script>
