<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-4 pl-0">
        <i v-show="fetchingCount" class="fa fa-spinner fa-spin"></i>
        <AlgTreeSelector :data="toDoMaintenancesTree" v-model="selectedTree" />
      </div>
      <div class="col-8">
        <AlgSelectToDoDetails :data="toDoMaintenancesTree" :selected-items="selectedTree" />
      </div>
    </div>
  </div>
</template>

<script>
import AlgTreeSelector from '@/components/tree/AlgTreeSelector.vue';
import AlgSelectToDoDetails from '@/components/toDoMaintenance/AlgSelectToDoDetails.vue';
import { mapState } from 'vuex';

export default {
  name: 'toDoMaintenanceView',
  components: {
    AlgTreeSelector,
    AlgSelectToDoDetails
  },
  data() {
    return  {
      selectedTree: []
    };
  },
  mounted() {
    this.selectedTree = this.getAllItemIds(this.toDoMaintenancesTree);
    setTimeout(() => {
      this.getManutenzioniToDo();
    }, 100);
  },
  watch: {
    toDoMaintenancesTree(val) {
      this.selectedTree = this.getAllItemIds(val);
    }
  },
  computed: {
     ...mapState('toDoMaintenancesModule', {
        'toDoMaintenancesTree': state => state.toDoMaintenancesTree,
        'fetchingCount': state => state.fetchingCount
      })
  },
  methods: {
    async getManutenzioniToDo() {
      this.$store.dispatch("toDoMaintenancesModule/loadToDoMaintenancesTree");
    },
    getAllItemIds(tree) {
      if(!tree || !tree.length || tree.length === 0)
        return [];
      let arr = [];
      tree.forEach(a => {
        arr.push(a.id);
        a.children.forEach(b => {
          arr.push(b.id);
          b.children.forEach(c => {
            arr.push(c.id);
            c.children.forEach(m => {
              arr.push(m.id);
            });
          });
        });
      });
      return arr;
    }
  }
}
</script>
